// This controller is loaded when the shared-ui--modal-ui--modal component is loaded
// and the view component is then moved to the body of the document in order to be
// properly positioned with a backdrop.

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {

  connect() {
    const targetId = this.element.dataset.targetId;
    const elementToRemove = document.getElementById(targetId);
    if (elementToRemove) {
      elementToRemove.remove();
    }
    // remove this invisible element
    setTimeout(() => this.element.remove(), 200);
  }
  
}
