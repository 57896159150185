/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    $('.tooltipster-prescription-cancellation-button').tooltipster({
      theme: 'tooltipster-noir',
      content: 'Loading...',
      contentAsHTML: true,
      minWidth: 400,
      delay: [900, 75],
      updateAnimation: 'fade',
      functionBefore: function(instance, helper) {
        const $origin = $(helper.origin);
        // // we set a variable so the data is only loaded once via Ajax, not every time the tooltip opens
        if ($origin.data('loaded') !== true) {
          const patientId = $origin.data('patient-id');
          const medicationId = $origin.data('medication-id');

          $.get(`/tooltips/patients/${patientId}/cancel_request_medication/${medicationId}`, function(data) {
            // call the 'content' method to update the content of our tooltip with the returned data
            // note: this content update will trigger an update animation (see the updateAnimation option)
            instance.content(data);
            // to remember that the data has been loaded
            $origin.data('loaded', true);
          })
        }
      }
    })
  }
}