/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

// This controller allows you implement a dynamic select dropdown that is dependent on another select dropdown

// To use this controller, follow these steps:

  // 1. Add the following data attributes to the parent element of the involved select dropdowns:
    // data-controller="shared-ui--form-ui--dynamic-select"
    // data-shared-ui--form-ui--dynamic-select-url-value="<%= THE ENDPOINT ROUTE PATH %>"
    // data-shared-ui--form-ui--dynamic-select-dependency-param-value="<%= THE PARAMETER NAME TO BE PASSED TO THE URL %>"

  // 2. Add the following data attribute to the select dropdown that will trigger the change event:
    // data-action="change->shared-ui--form-ui--dynamic-select#updateSelectOptions"

  // 3. Add the following data attributes to the select dropdown that will be updated:
    // data-shared-ui--form-ui--dynamic-select-target="selectDropdown"

  // 4. Create a new endpoint route that will return the updated select dropdown options to a turbo stream template with the following instance variables set:
    // @target = params[:target]
    // @updated_options = <THE SELECT DROPDOWN OPTIONS TO BE UPDATED>

  // 5. Add the following code snippet to the turbo stream template:
    // <%= turbo_stream.update @target do %>
    //   <ADD WHATEVER THE TEMPLATE SHOULD BE UPDATED WITH>
    //   <USE 'options_for_select @updated_options' TO UPDATE THE SELECT DROPDOWN OPTIONS>
    // <% end %>

import { Controller } from "@hotwired/stimulus";
import RequestController from "../../utilities/request_controller";

export default class extends Controller{
  static targets = [
    "selectDropdown"
  ];
  static values = {
    url: String,
    dependencyParam: String,
  };


  connect() {
    if (!this.selectDropdownTarget.id) {
      this.selectDropdownTarget.id = Math.random().toString(36)
    }
  }

  updateSelectOptions(event) {
    const requester = new RequestController;
    const dependencyOptionSelected = event.target.value;
    const params = new URLSearchParams();
    
    params.append("target", this.selectDropdownTarget.id);
    params.append(this.dependencyParamValue, dependencyOptionSelected);
    const url = `${this.urlValue}?${params}`;

    requester.fetchTurboStream({ params: { url: url } });
  }

}
