import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    // Wait for the next frame to ensure DOM is updated
    requestAnimationFrame(() => {
      this.initializeTooltips()
    })
  }

  initializeTooltips() {
    const tooltipElements = [
      ...this.element.querySelectorAll('.tooltipster'),
      ...(this.element.classList.contains('tooltipster') ? [this.element] : [])
    ]

    tooltipElements.forEach(element => {
      if (!$(element).hasClass('tooltipstered')) {
        $(element).tooltipster({
          theme: 'tooltipster-noir',
          contentAsHTML: true
        })
      }
    })
  }

  disconnect() {
    const tooltipElements = [
      ...this.element.querySelectorAll('.tooltipster'),
      ...(this.element.classList.contains('tooltipster') ? [this.element] : [])
    ]

    tooltipElements.forEach(element => {
      // Check both for tooltipstered class and if tooltipster instance exists
      if ($(element).hasClass('tooltipstered') && $(element).data('tooltipster-ns')) {
        try {
          $(element).tooltipster('destroy')
        } catch (e) {
          console.warn('Failed to destroy tooltipster:', e)
        }
      }
    })
  }

}