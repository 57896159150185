import RequestController from "@components/utilities/request_controller"

export class InfiniteScroller {
  constructor(listContainer, url) {
    this.listContainer = listContainer
    this.requester = new RequestController
    this.isLoading = false
    this.currentlyLoadingPage = null
    this.loadingError = null
    this.url = url
    this.setupSentinel() // sentinel is a div that is observed to trigger loading more content
    this.setupIntersectionObserver()
    if (!this.listContainer) {
      throw new Error('InfiniteScroller: List container element not found')
    }
    if (!this.url) {
      throw new Error('InfiniteScroller: URL not found')
      
    }
  }

  setupSentinel() {
    const existingSentinel = this.listContainer.querySelector('[infinite-scroll-sentinel]')
    if (existingSentinel) return
    
    this.sentinel = document.createElement('div')
    this.sentinel.style.height = '1px'
    this.sentinel.setAttribute('infinite-scroll-sentinel', '')
    this.listContainer.appendChild(this.sentinel)
  }

  setupIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      entries => this.handleIntersection(entries),
      { 
        root: this.listContainer,
        threshold: 0,
        rootMargin: "50px 0px"
      }
    )

    this.intersectionObserver.observe(this.sentinel)
  }

    // Content Loading
  async handleIntersection(entries) {
    const pageInfo = this.getPagyInfo()
    const isIntersecting = entries.some(entry => entry.isIntersecting)
    if (!isIntersecting || pageInfo.isLastPage) return

    const nextPage = pageInfo.currentPage + 1
    await this.loadMore(nextPage, pageInfo.params)
  }

  async loadMore(nextPage, params = {}) {
    
    if (this.needToStopLoadingThreads(nextPage)) return
    this.isLoading = true
    this.currentlyLoadingPage = nextPage
    
    try {
      this.intersectionObserver.unobserve(this.sentinel)
      await this.fetchItems(nextPage, params)
      
      this.loadingError = null
      this.isLoading = false
    } catch (error) {
      this.loadingError = error //prevents infinite loop of re-fetching when observing sentinel
      console.error('Error loading more threads:', error)
    } finally {      
      this.isLoading = false
      this.intersectionObserver.observe(this.sentinel)
    }
  }

  needToStopLoadingThreads(nextPage) {
    return this.isLoading || this.currentlyLoadingPage === nextPage || Boolean(this.loadingError)
  }

  async fetchItems(page, params = {}) {
    if (!this.url) {
      throw new Error('fetchItems: URL not found')
    }

    const searchParams = new URLSearchParams({ page, ...params })
    console.log('params', params);
    console.log('searchParams.toString()', searchParams.toString());
    const urlWithParams = `${this.url}?${searchParams.toString()}`
    
    await this.requester.fetchTurboStream({ params: { url: urlWithParams } })
  }

  getPagyInfo() {
    const el = this.listContainer.querySelector('.pagy-info')
    if (!el) {
      console.error('Pagy info not found. Use SharedUi::PagyInfo::Component at the top of the list')
      this.intersectionObserver.unobserve(this.sentinel)
      this.removeSentinels()
      return { isLastPage: true, currentPage: 0 }
    }

    const { isLastPage, currentPage, ...otherParams } = Object.fromEntries(
      Object.entries(el.dataset)
    )

    const snakedParams = Object.fromEntries(
      Object.entries(otherParams).map(([key, value]) => [
        key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/^_/, ''),
        value
      ])
    )

    // Convert DOMStringMap to plain object and spread it in return
    return {
      isLastPage: el.dataset.isLastPage === 'true',
      currentPage: parseInt(el.dataset.currentPage || 0),
      params: snakedParams
    }
  }

  removeSentinels() {
    document.querySelectorAll('[infinite-scroll-sentinel]').forEach(el => el.remove());
  }

}
